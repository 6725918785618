.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background: white; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  position: relative;
  height: 300px; /* Kann bei Bedarf angepasst werden */
  background-image: url('/backgorund.jpg');
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.separator {
  width: 50%; /* Breite des Strichs (z.B. 50% der Containerbreite) */
  height: 2px; /* Höhe des Strichs */
  background: linear-gradient(to right, transparent, #e85f5f, transparent); /* Leichtes Rot mit Gradient-Effekt */
  margin: 5% auto; /* Zentriert den Strich und gibt etwas Abstand */
}
.headline{
  margin: 5%;
}
.button {
  margin: 0 20px;
  padding: 12px 32px; /* Größere horizontale Polsterung für breitere Buttons */
  background-color: #c52a2a; /* Leichtes Rot für den Button-Hintergrund */
  border: none; /* Entfernt den Rand */
  border-radius: 30px; /* Abgerundete Ecken für einen modernen Look */
  color: white; /* Weißer Text für hohen Kontrast */
  font-size: 16px; /* Angemessene Schriftgröße */
  font-weight: bold; /* Fettgedruckter Text */
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Leichter Schatten für Tiefe */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Sanfte Übergänge */
}

.button:hover {
  background-color: #E04B2A; /* Dunkleres Rot beim Hover-Effekt */
  transform: translateY(-2px); /* Leichter Schwebeeffekt beim Hover */
}

.button:active {
  background-color: #C44223; /* Noch dunkleres Rot beim Klicken */
  transform: translateY(0); /* Zurücksetzen des Schwebens bei Aktivierung */
}


.content {
  padding: 20px;
}

.section {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}

.image {
  width: 60%;
  height: auto;
  padding: 0; 
  
}

.text {
  padding: 1%; /* Einheitlicher Innenabstand */
  margin-left:0%; /* Vermeidung von zusätzlichem Außenabstand */
  font-size: 19px; /* Einheitliche Schriftgröße */
  line-height: 1.5; /* Einheitliche Zeilenhöhe für bessere Lesbarkeit */
  box-sizing: border-box; /* Berücksichtigt Padding in der Breite */
  width: 50%; 
  text-align: left; /* Macht den Text linksbündig */

  
}

.image {
  background-color: #f0f0f0;
  text-align: center;
  width: 50%; 
  height: auto;
  
}
.icon-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon-header img.icon {
  width: 30px; /* Größe des Icons anpassen */
  height: 30px; /* Größe des Icons anpassen */
  margin-right: 10px; /* Abstand zwischen Icon und Überschrift */
}


.modal {
  display: none; /* Hidden by default */
  position: relative; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 60px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Breite des Modals */
  max-width: 800px; /* Maximale Breite für das Modal */
}

.video-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 56.25%; /* Verhältnis für 16:9 Videos */
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}




.footer {
  background-color: #333;
  color: white;
  padding: 10px 0;
  position: relative; /* Sicherstellen, dass der Footer nicht fixiert ist */
  width: 100%;
  text-align: center;
  z-index: 1; /* Footer hat einen niedrigen z-index */
}

.footer-links {
  list-style-type: none; /* Entfernt die Punkte vor den Links */
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.footer-links li {
  margin: 0 15px;
}

.footer-links a {
  color: white; /* Setzt die Linkfarbe auf weiß */
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.modal {
  display: none;
  position: fixed; /* Ändert das Modal auf fixed, damit es über dem Inhalt liegt */
  z-index: 1000; /* Sehr hoher z-index, um sicherzustellen, dass das Modal über allem liegt */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: white; 
}

.modal-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.image img {
  max-width: 100%; /* Bild passt sich der Breite des übergeordneten Containers an */
  height: auto;    /* Höhe passt sich proportional an */
  display: block;
  margin: 0 auto;  /* Bild wird zentriert */
}

.text {
  padding: 20px;
  margin-left: 0%;
  font-size: 19px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 50%;
  text-align: left;
  background: rgba(250, 243, 243, 0.3);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 150, 150, 0.5);
}
/* Mobile Styles */
@media (max-width: 768px) {

  .header {
    background-image: url('/background_mobile.jpg'); /* Mobiler Hintergrund */
    height: 250px; /* Höhe kann bei Bedarf angepasst werden */
  }
  .section {
    flex-direction: column; /* Bilder und Text untereinander anordnen */
    align-items: center;
    margin-bottom: 5%;
  }
  

  .text, .image {
    width: 50%; /* Nimmt die gesamte Breite ein */
    text-align: center;
    background: rgb(246, 246, 246);
    margin-bottom: 5%;
    margin-top: 10%;
  }

  .separator {
    
    margin: 10% auto; /* Zentriert den Strich und gibt etwas Abstand */
  }

  .icon-header {
    flex-direction: column; /* Ändert die Ausrichtung auf vertikal */
    align-items: center; /* Zentriert das Icon und die Überschrift */
  }

  .icon-header img.icon {
    margin-right: 0; /* Entfernt den rechten Rand */
    margin-bottom: 5px; /* Fügt einen unteren Rand hinzu, um das Icon von der Überschrift zu trennen */
  }

  .text {
    order: 1; /* Text immer zuerst */
    padding: 20px; /* Einheitlicher Innenabstand */
    margin: 0; /* Vermeidung von zusätzlichem Außenabstand */
    font-size: 16px; /* Einheitliche Schriftgröße */
    line-height: 1.5; /* Einheitliche Zeilenhöhe für bessere Lesbarkeit */
    box-sizing: border-box; /* Berücksichtigt Padding in der Breite */
    width: 100%;  
    
  }

  .image {
    order: 2; /* Bild immer nach dem Text */
    width: 100%; 
    height: 100%; 
    padding: 0; 
  }
  .footer-links {
    display: flex;
    flex-direction: column; /* Ändert die Anordnung auf vertikal */
    align-items: center; /* Zentriert die Links */
  }

  .footer-links li {
    margin: 10px 0; /* Fügt Abstand zwischen den Links hinzu */
  }

  .footer-links a {
    font-size: 16px; /* Größere Schriftgröße für bessere Lesbarkeit auf Mobilgeräten */
    text-decoration: none;
  }
}
/* Desktop Styles */
@media (min-width: 769px) {

  .section {
    display: flex;
    justify-content: space-between;
    margin-left: 10%;
    margin-right: 5%;
    margin-top: 5%;
    
  }
 

  .section:nth-of-type(odd) {
    flex-direction: row; /* Bild links, Text rechts für ungerade Abschnitte */
  }

  .section:nth-of-type(even) {
    flex-direction: row-reverse; /* Bild rechts, Text links für gerade Abschnitte */
  }

  .image, .text {
    width: 50%; /* Jedes Element nimmt 50% der Breite ein */
  }

  .footer-links {
    display: flex;
    justify-content: space-around;
  }
}

